import "@/app/globals.css";
import withRuntimeEnv from "@/base/hoc/runtime-env";
import { GlobalStateProvider } from "@/base/state";
import { ConfigProvider } from "antd";
import enUS from "antd/locale/en_US";
import { Roboto } from "next/font/google";
import React from "react";
import StyledComponentsRegistry from "../base/lib/AntdRegistry";
import buConfig from "../../business-unit.json";
import theme from "./theme";

export const roboto = Roboto({
  weight: ["100", "300", "400", "500", "700", "900"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

// export const metadata = {
//   title: buConfig.applicationName,
//   description: "Booking Engine System",
// };

const RootLayout = ({ children }: React.PropsWithChildren) => (
  <html lang="en">
    <head>
      <meta name="robots" content="noindex, nofollow" />
    </head>
    <body className={roboto.className}>
      <ConfigProvider locale={enUS} theme={theme}>
        <StyledComponentsRegistry>
          <GlobalStateProvider>{children}</GlobalStateProvider>
        </StyledComponentsRegistry>
      </ConfigProvider>
    </body>
  </html>
);

export default withRuntimeEnv(RootLayout);
