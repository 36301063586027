'use client';
import { BUSINESS_ID } from "./[env]/[slug]/BusinessSwitch";
import Layout from "./layout";
import { usePathname, redirect } from "next/navigation";

export default function NotFound({ noLayout }: any) {

  const pathname = usePathname();
  const children = <div>404 - Not Found</div>; // TODO need to define UI here

  if(!pathname.includes("phv") && !pathname.includes("personal") && !pathname.includes("econtract")) {
    const sessionBusinessId = sessionStorage.getItem(BUSINESS_ID);
    window.location.href = `/${sessionBusinessId === '2' ? 'phv': 'personal'}/${pathname}`;
  }
  if (noLayout) {
    return children;
  }

  return <Layout>{children}</Layout>;
}
