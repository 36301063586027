import { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    fontFamily:
      "'__Roboto_a782de', '__Roboto_Fallback_a782de', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    lineHeight: 1.3,
    fontSizeHeading1: 24,
    fontSizeHeading2: 20,
    fontSizeHeading3: 16,
    fontSizeHeading4: 14,
  },
  components: {
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Button: {
      fontWeight: 500,
      colorError: "#CF1322",
      colorErrorHover: "#f5222d",
    },
  },
};

export default theme;
