'use client'
import { Tabs } from "antd/lib";

import { useEffect, useRef, useState } from "react";
import './index.scss'
import { useGlobalState } from "@/base/state/slice";
import { useParams } from "next/navigation";

export const BUSINESS_ID = 'Business-Id';

export const BusinessSwitch = () => {
  const params = useParams();
  const ref = useRef(null);
  const sessionBusinessId = sessionStorage.getItem(BUSINESS_ID);
  const { update: setBusinessId, state: businessId } = useGlobalState<string>(`app.businessId`);
  // const [businessId, setBusinessId] = useState(sessionBusinessId || '1')
  const onChange = (key: string) => {
    const env = key === '2' ? 'phv' : 'personal';
    setTimeout(() => {
      window.location.href = `/${env}/order`;
      setBusinessId(key);
      sessionStorage.setItem(BUSINESS_ID, key);
    }, 350); //order有一个300ms的onblur事件，所以这里要延迟350ms
    
  }
  useEffect(() => {
    setBusinessId(sessionBusinessId || '1');
  }, [sessionBusinessId]);
  useEffect(() => {
    if (params.env === 'personal') {
      if (businessId !== '1') {
        setBusinessId('1');
        sessionStorage.setItem(BUSINESS_ID, '1');
      };
    } else if (params.env === 'phv') {
      if (businessId !== '2') {
        setBusinessId('2')
        sessionStorage.setItem(BUSINESS_ID, '2');
      };
    }
    //  else {
    //   // 如果没有env参数，那么就根据sessionStorage来判断
    //   if (sessionBusinessId) {
    //     if (sessionBusinessId === '1') {
    //       window.location.href = '/personal/order';
    //     } else {
    //       window.location.href = '/phv/order';
    //     }
    //   }

    // }
  }, [params.env]);
  const items = [{
    label: `Personal Rental`,
    key: '1',
  }, {
    label: `Short Term PHV Rental`,
    key: '2',
  }]
  return (
    <div className="business-switch">
      <Tabs activeKey={businessId} defaultActiveKey={businessId || sessionBusinessId || '1'} items={items} onTabClick={onChange} />
    </div>
  )
}